class ScriptPromise {
  id = ''
  el = document.createElement('script')

  constructor (id: number | string) {
    this.id = id.toString()
    this.el.setAttribute('id', id.toString())
  }

  src (value: string) {
    this.el.setAttribute('src', value)

    return this
  }

  content (value: string) {
    this.el.innerHTML = value

    return this
  }

  config (opts: { async?: boolean, type?: string, crossOrigin?: string }) {
    if (opts.async) this.el.async = opts.async
    if (opts.type) this.el.type = opts.type
    if (opts.crossOrigin) this.el.crossOrigin = opts.crossOrigin

    return this
  }

  load () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _t = this

    return new Promise((resolve, reject) => {
      this.el.addEventListener('load', () => resolve(_t))

      this.el.addEventListener('error', () => {
        reject(new Error(`Failed to load script ${this.id}`))
      })

      document.head.appendChild(this.el)
    })
  }
}

export function loadScript (id: number | string) {
  return new ScriptPromise(id)
}
