import type { ComputedRef } from 'vue'
import { reactive, computed } from 'vue'

export type Role = 'unauthenticated' | 'customer' | 'admin'

type AccountManager = {
  isAdmin: ComputedRef<boolean>
  isUnauthenticated: ComputedRef<boolean>
  isCustomer: ComputedRef<boolean>
  setRole: (role: Role) => void
}

type State = {
  role: Role
}

const state = reactive<State>({ role: 'unauthenticated' })

export const useAccount = (): AccountManager => {
  const isAdmin = computed(() => state.role === 'admin')
  const isUnauthenticated = computed(() => state.role === 'admin')
  const isCustomer = computed(() => state.role === 'customer')

  const setRole: AccountManager['setRole'] = (role) => {
    state.role = role
  }

  return {
    isAdmin,
    isUnauthenticated,
    isCustomer,
    setRole
  }
}
