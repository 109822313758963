import type { TranslationProxy } from 'intl-schematic'
import { createTranslator } from 'intl-schematic'
import { useContextData, useHydrationStore } from '#imports'
import { useCookie } from '#app'
import { computed } from 'vue'

export function useAdmin () {
  const expiresDate = new Date()
  const host = useRequestURL().host ?? ''
  const expiresDatePlusYear = new Date(expiresDate.setFullYear(expiresDate.getFullYear() + 1))

  const state = useHydrationStore('admin-localization', {
    locale: 'en',
    fallbackLocale: 'en'
  })
  const components = useHydrationStore('admin-components', {})
  const messages = useContextData<Record<string, string>>('admin-messages', {})

  const getDocument = () => messages.value
  const getLocale = () => new Intl.Locale(state.value.locale)

  async function setLocale (lang: string) {
    if (lang !== state.value.locale || Object.entries(messages.value).length === 0) {
      messages.value = await loadDynamicMessages(lang)
    }

    state.value.locale = lang
  }

  async function loadDynamicMessages (lang: string) {
    const messages = await import(`./messages/${lang}.json?raw`)

    return JSON.parse(messages.default)
  }

  let hostWithoutSubdomains = `.${host}`
  hostWithoutSubdomains = hostWithoutSubdomains.split(':')[0]

  const role = useCookie<string>('role', {
    path: '/',
    secure: true,
    expires: expiresDatePlusYear,
    domain: hostWithoutSubdomains
  })
  const isAdmin = computed(() => role.value === 'admin')

  function getTranslator (): TranslationProxy<any, any> {
    return createTranslator(getDocument, getLocale)
  }

  function defineComponents (toAdd: Record<string, any>) {
    components.value = { ...components.value, ...toAdd }
  }

  return {
    isAdmin,
    setLocale,
    defineComponents,
    t: getTranslator()
  }
}
