export class RedirectError extends Error {
  redirectPath: string

  constructor (name: string, redirectPath: string) {
    super(redirectPath)

    this.name = name
    this.redirectPath = redirectPath
  }
}
