import type { Message } from 'google-protobuf'
import type { Request, UnaryInterceptor, UnaryResponse } from 'grpc-web'
import type { useSentry } from '#imports'

type InterceptorRequest = Request<Message, Message>
type InterceptorUnaryResponse = UnaryResponse<Message, Message>

export class MetadataInterceptor implements UnaryInterceptor<Message, Message> {
  locale: string
  region: string
  timeout: number
  sentry: ReturnType<typeof useSentry>

  constructor (sentry: ReturnType<typeof useSentry>, locale: string, region: string, timeout: number) {
    this.locale = locale
    this.region = region
    this.timeout = timeout
    this.sentry = sentry
  }

  intercept (
    request: InterceptorRequest,
    invoker: (invokerRequest: InterceptorRequest) => Promise<InterceptorUnaryResponse>
  ): Promise<InterceptorUnaryResponse> {
    const deadline = new Date()
    deadline.setSeconds(deadline.getSeconds() + this.timeout)

    request.getMetadata()['Accept-Language'] = this.locale
    // @ts-expect-error
    request.getMetadata()['X-Metadata-Region'] = request.getRequestMessage().region ?? this.region
    request.getMetadata()['deadline'] = deadline.getTime().toString()

    this.sentry.setTag('locale', this.locale)
    this.sentry.setTag('region', this.region)

    return invoker(request)
  }
}
