<template>
  <component
    :is="`h${level}`"
    :class="classes"
  >
    <slot />
  </component>
</template>

<script lang="ts">
import { defineComponent, toRefs, computed } from 'vue'
import type { PropType } from 'vue'

export type HeadingSize = '6xl' | '5xl' | '4xl' | '3xl' | '2xl' | 'xl' | 'lg' | 'base' | 'sm' | 'xs'
export type HeaderLevel = '1' | '2' | '3' | '4' | '5' | '6' | 1 | 2 | 3 | 4 | 5 | 6

export default defineComponent({
  name: 'AHeading',
  props: {
    level: {
      type: [String, Number] as PropType<HeaderLevel>,
      required: true
    },
    size: {
      type: String as PropType<HeadingSize | ''>,
      default: ''
    }
  },
  setup (props) {
    const { size } = toRefs(props)

    const classes = computed(() => {
      const list = ['heading']

      if (size.value !== '') { list.push(`heading--${size.value}`) }

      return list.join(' ')
    })

    return { classes }
  }
})
</script>

<style lang="postcss">
[class^="heading--"],
.heading {
  color: var(--heading-color, var(--color-text-dark));
  white-space: normal;

  @media (--screen-xs) {
    user-select: none;
  }
}

.heading {
  --heading-color: initial;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.heading--6xl {
  @mixin text-6xl;
}

.heading--5xl {
  @mixin text-5xl;
}

.heading--4xl {
  @mixin text-4xl;
}

.heading--3xl {
  @mixin text-3xl;
}

.heading--2xl {
  @mixin text-2xl;
}

.heading--xl {
  @mixin text-lg;
}

.heading--lg {
  @mixin text-base-bold;
}

.heading--base {
  @mixin text-base-semibold;
}

.heading--sm {
  @mixin text-sm-bold;
}

.heading--xs {
  @mixin text-sm-semibold;
}
</style>
