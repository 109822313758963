import { computed } from 'vue'

export function useLocalStorage (key: string) {
  return computed({
    get: () => {
      if (process.server) return null

      return localStorage.getItem(key)
    },
    set: (value) => {
      if (process.server) return null

      if (value === null || value === undefined) {
        localStorage.removeItem(key)
      } else {
        localStorage.setItem(key, value)
      }
    }
  })
}
