import type { PushParameter } from 'notivue'
import { push } from 'notivue'

export function useNotify () {
  function info (notification: PushParameter) {
    return push.info(notification)
  }

  function success (notification: PushParameter) {
    return push.success(notification)
  }

  function error (notification: PushParameter) {
    return push.error(notification)
  }

  function warning (notification: PushParameter) {
    return push.warning(notification)
  }

  function promise (notification: PushParameter) {
    return push.promise(notification)
  }

  function clearAll () {
    push.clearAll()
  }

  function destroyAll () {
    push.destroyAll()
  }

  return {
    info,
    success,
    error,
    warning,
    promise,
    clearAll,
    destroyAll
  }
}
