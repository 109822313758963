import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import { useRoute } from 'vue-router'
import { loadScript } from '../utils'
import { useConfig } from '@/stores/config'
import type { RegionsEnvsConfig } from '@/types/regionEnvs'

const temp = (id: number | string) => `var loadedMetricaClicky = false, timerId;

window.addEventListener( 'scroll', loadClicky, {passive: true} );
window.addEventListener( 'touchstart', loadClicky );
document.addEventListener( 'mouseenter', loadClicky );
document.addEventListener( 'click', loadClicky );
document.addEventListener( 'DOMContentLoaded', loadFallback );

function loadFallback() {
    timerId = setTimeout( loadClicky, 1000 );
}

function loadClicky( e ) {
    if ( loadedMetricaClicky ) {
      return;
    }

    const clicky = window.clicky || {};
    const script =  document.script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = '//static.getclicky.com/${id}.js';
    document.body.appendChild(script);

    loadedMetricaClicky = true;
    clearTimeout( timerId );
    window.removeEventListener( 'scroll', loadClicky );
    window.removeEventListener( 'touchstart', loadClicky );
    document.removeEventListener( 'mouseenter', loadClicky );
    document.removeEventListener( 'click', loadClicky );
    document.removeEventListener( 'DOMContentLoaded', loadFallback );
}`

export class Clicky {
  id = 0
  loading = true
  jobs: (() => void)[] = []

  constructor (id: number) {
    this.id = id
  }

  getNamePage () {
    const route = useRoute()

    switch (route.name) {
      case 'index':
        return 'Главная страница'
      case 'category':
        return 'Каталога ' + route.path
      case 'cart':
        return 'Корзины'
      case 'checkout':
        return 'Чекаута'
      case 'account':
        return 'Личного кабинета'
      case 'products-slug-index':
        return 'Продуктовой страницы'
      default:
        return 'Главная страница'
    }
  }

  goal (event: string, data: string) {
    if (event === 'add') {
      window.clicky.goal(`В корзину добавили из  ${this.getNamePage()}`)
    }

    if (event === 'count') {
      window.clicky.goal(`Общее количество добавлений в корзину ${data}`)
    }
  }

  log (url: string, title: string) {
    if (this.loading) {
      this.jobs.push(() => window.clicky.log(url, title))
    } else {
      window.clicky.log(url, title)
    }
  }

  ready () {
    this.loading = false

    for (const job of this.jobs) {
      job()
    }
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { currentRegion } = useConfig()
  const analyticsRegionsJson = config.public.regionsEnvsJson as unknown as RegionsEnvsConfig
  const id = analyticsRegionsJson[currentRegion.value.id]?.clickyId as number

  if (isNaN(id)) {
    return
  }

  const content = temp(id)
  const clicky = new Clicky(id)

  let loaded = false

  nuxtApp.hook('page:finish', () => {
    if (loaded) { return }
    loadScript(id)
      .content(content)
      .load()
      .then(() => clicky.ready())
    loaded = true
  })

  nuxtApp.hooks.callHook('analytics:clicky:init')

  return {
    provide: {
      clicky
    }
  }
})

declare global {
  interface Window {
    clicky: {
      goal: any
      log: any
    }
  }
}
