import { defineNuxtRouteMiddleware, navigateTo } from '#app'
import { useConfig } from '@/stores/config'

export default defineNuxtRouteMiddleware((to) => {
  const { currentRegion } = useConfig()

  const redirects: Array<[RegExp, string]> = [
    [/\/rush_buy\/?/i, '/'],
    [/\/rush_buy\/reviews\/?/i, '/'],
    [/\/pages\/for_lessors\.html/i, '/'],
    [/\/pages\/wine_tasting\.html/i, '/']
  ]

  if (currentRegion.value.id !== 1) {
    for (const redirect of redirects) {
      if (redirect[0].test(to.path)) {
        return navigateTo(redirect[1])
      }
    }
  }
})
