import { defineNuxtRouteMiddleware, useNuxtApp, useRequestURL } from '#app'
import { useHydrationData } from '#imports'
import { useCategories } from '@/stores/categories'
import { useShopStore } from '@/stores/shop'
import { useConfig } from '@/stores/config'

export default defineNuxtRouteMiddleware(async (to) => {
  const name = to.name?.toString()
  const { getCategories } = useCategories()
  const { getShops, shopListData } = useShopStore()
  const { currentRegion } = useConfig()

  const host = useRequestURL().host ?? ''

  const referrer = import.meta.server
    ? useNuxtApp().ssrContext?.event.node.req.headers.referer
    : document.referrer

  const hostWithoutSubdomains = host.split(':')[0]
    .replace(currentRegion.value.subdomain || '', '')
    .replace(/^\./g, '')

  const [{ data: categories }] = await Promise.all([
    useHydrationData('category-list', () => getCategories()).catch(() => ({ data: undefined })),
    getShops().catch(() => ({ data: undefined }))
  ])

  if (name === 'category-index' || name === 'category-filters') {
    const categoriesCodes = (categories?.value || []).map(el => el.code)
    const shopsCodes = (shopListData?.value || []).map(el => el.code)

    categoriesCodes.push('cocktails')

    const isDelicacies = to.params.category === 'delicacies'
    const isNovelty = to.params.category === 'novelty'
    const isCategory = categoriesCodes.includes(to.params.category as string)
    const isShop = shopsCodes.includes(to.params.category as string)

    if (isDelicacies || !(isCategory || isNovelty || isShop)) {
      let referrerURL = ''
      try {
        if (referrer) {
          const url = new URL(referrer)
          referrerURL = `${url.protocol}//${url.hostname}`
        }
      } catch (error) {
        console.error('Invalid referrer URL:', error)
      }

      if (referrerURL.includes(hostWithoutSubdomains)) {
        return await navigateTo('/')
      }

      throw createError({ statusCode: 404, message: `Category [${to.params.category}] not found`, fatal: true })
    }
  }
})
