import { default as _91shop_93_45shoplerbZCDSxkMeta } from "/app/pages/[shop]-shop.vue?macro=true";
import { default as _5029GGsrM9H9jMeta } from "/app/pages/502.vue?macro=true";
import { default as contentVzrMNRiXBUMeta } from "/app/pages/articles/content.vue?macro=true";
import { default as indexnFjuerN8cOMeta } from "/app/pages/articles/index.vue?macro=true";
import { default as auth_45testBUaNmB5xp3Meta } from "/app/pages/auth-test.vue?macro=true";
import { default as cart_46phpUaKvzlU2YnMeta } from "/app/pages/cart.php.vue?macro=true";
import { default as indexvJKqjSbZLzMeta } from "/app/pages/catalog/index.vue?macro=true";
import { default as filtersThvRgz34YJMeta } from "/app/pages/category/filters.vue?macro=true";
import { default as indexTKse6KvRRRMeta } from "/app/pages/category/index.vue?macro=true";
import { default as checkoutLpmDViNIqPMeta } from "/app/pages/checkout.vue?macro=true";
import { default as emptyJ3xHFAFI9fMeta } from "/app/pages/empty.vue?macro=true";
import { default as errors5uvLjYG1s0Meta } from "/app/pages/errors.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as noveltydHIUSu1FCUMeta } from "/app/pages/novelty.vue?macro=true";
import { default as opthQTr7D4tQHMeta } from "/app/pages/opt.vue?macro=true";
import { default as about_46htmlulSueykfEnMeta } from "/app/pages/pages/about.html.vue?macro=true";
import { default as bestsellers_46htmlyj2uwVjm8nMeta } from "/app/pages/pages/bestsellers.html.vue?macro=true";
import { default as charityFkuUSG22zSMeta } from "/app/pages/pages/charity.vue?macro=true";
import { default as contacts_46htmlD8A6XnDtdtMeta } from "/app/pages/pages/contacts.html.vue?macro=true";
import { default as corporate_46htmliJ6FLPij8OMeta } from "/app/pages/pages/corporate.html.vue?macro=true";
import { default as delivery_46htmlucMSYyvLhQMeta } from "/app/pages/pages/delivery.html.vue?macro=true";
import { default as for_lessors_46htmlLlnorFbRclMeta } from "/app/pages/pages/for_lessors.html.vue?macro=true";
import { default as franchise_46htmlIfFe2M2JIdMeta } from "/app/pages/pages/franchise.html.vue?macro=true";
import { default as legal_46htmlBwckzuaJlGMeta } from "/app/pages/pages/legal.html.vue?macro=true";
import { default as map_46htmlEheqGURPosMeta } from "/app/pages/pages/map.html.vue?macro=true";
import { default as oneday_46htmlFHe5ivHFjWMeta } from "/app/pages/pages/oneday.html.vue?macro=true";
import { default as suppliers_46htmlnJJ5lSDxSWMeta } from "/app/pages/pages/suppliers.html.vue?macro=true";
import { default as vacancyrIJfFt9QeoMeta } from "/app/pages/pages/vacancy.vue?macro=true";
import { default as warranty_46htmljYk8XR3unzMeta } from "/app/pages/pages/warranty.html.vue?macro=true";
import { default as bonuses0zC88rvptiMeta } from "/app/pages/private_office/bonuses.vue?macro=true";
import { default as index3CKdqKlWQtMeta } from "/app/pages/private_office/index.vue?macro=true";
import { default as my_45companyenFydBlYDiMeta } from "/app/pages/private_office/my-company.vue?macro=true";
import { default as ordersmn7HQhwEdGMeta } from "/app/pages/private_office/orders.vue?macro=true";
import { default as profileW0kWFDGH6xMeta } from "/app/pages/private_office/profile.vue?macro=true";
import { default as purchasesjhjdgKy1dWMeta } from "/app/pages/private_office/purchases.vue?macro=true";
import { default as review_45bonusesTHgtYoervdMeta } from "/app/pages/private_office/review-bonuses.vue?macro=true";
import { default as reviewsgQxFaXnNrwMeta } from "/app/pages/private_office/reviews.vue?macro=true";
import { default as saved_45articlesfEZJtACz9MMeta } from "/app/pages/private_office/saved-articles.vue?macro=true";
import { default as wishlist_46phpEHmfw0BohOMeta } from "/app/pages/private_office/wishlist.php.vue?macro=true";
import { default as private_officeiJfgU5aBGwMeta } from "/app/pages/private_office.vue?macro=true";
import { default as indexsLN0z2PK5UMeta } from "/app/pages/products/[slug]/index/index.vue?macro=true";
import { default as reviewsiZDsgWhRwUMeta } from "/app/pages/products/[slug]/index/reviews.vue?macro=true";
import { default as indexrLz3LKieQqMeta } from "/app/pages/products/[slug]/index.vue?macro=true";
import { default as similar2t0l4jX5VSMeta } from "/app/pages/products/[slug]/similar.vue?macro=true";
import { default as testL3AVDvhyXeMeta } from "/app/pages/test.vue?macro=true";
export default [
  {
    name: _91shop_93_45shoplerbZCDSxkMeta?.name ?? "shop-shop",
    path: _91shop_93_45shoplerbZCDSxkMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/:shop()-shop",
    meta: _91shop_93_45shoplerbZCDSxkMeta || {},
    alias: _91shop_93_45shoplerbZCDSxkMeta?.alias || [],
    redirect: _91shop_93_45shoplerbZCDSxkMeta?.redirect,
    component: () => import("/app/pages/[shop]-shop.vue").then(m => m.default || m)
  },
  {
    name: _5029GGsrM9H9jMeta?.name ?? "502",
    path: _5029GGsrM9H9jMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/502",
    meta: _5029GGsrM9H9jMeta || {},
    alias: _5029GGsrM9H9jMeta?.alias || [],
    redirect: _5029GGsrM9H9jMeta?.redirect,
    component: () => import("/app/pages/502.vue").then(m => m.default || m)
  },
  {
    name: contentVzrMNRiXBUMeta?.name ?? "articles-content",
    path: contentVzrMNRiXBUMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/articles/content",
    meta: contentVzrMNRiXBUMeta || {},
    alias: contentVzrMNRiXBUMeta?.alias || [],
    redirect: contentVzrMNRiXBUMeta?.redirect,
    component: () => import("/app/pages/articles/content.vue").then(m => m.default || m)
  },
  {
    name: indexnFjuerN8cOMeta?.name ?? "articles",
    path: indexnFjuerN8cOMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/articles",
    meta: indexnFjuerN8cOMeta || {},
    alias: indexnFjuerN8cOMeta?.alias || [],
    redirect: indexnFjuerN8cOMeta?.redirect,
    component: () => import("/app/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: auth_45testBUaNmB5xp3Meta?.name ?? "auth-test",
    path: auth_45testBUaNmB5xp3Meta?.path ?? "/:locale(ru|en|uk|kz|en)?/auth-test",
    meta: auth_45testBUaNmB5xp3Meta || {},
    alias: auth_45testBUaNmB5xp3Meta?.alias || [],
    redirect: auth_45testBUaNmB5xp3Meta?.redirect,
    component: () => import("/app/pages/auth-test.vue").then(m => m.default || m)
  },
  {
    name: cart_46phpUaKvzlU2YnMeta?.name ?? "cart.php",
    path: cart_46phpUaKvzlU2YnMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/cart.php",
    meta: cart_46phpUaKvzlU2YnMeta || {},
    alias: cart_46phpUaKvzlU2YnMeta?.alias || [],
    redirect: cart_46phpUaKvzlU2YnMeta?.redirect,
    component: () => import("/app/pages/cart.php.vue").then(m => m.default || m)
  },
  {
    name: indexvJKqjSbZLzMeta?.name ?? "catalog",
    path: indexvJKqjSbZLzMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/catalog",
    meta: indexvJKqjSbZLzMeta || {},
    alias: indexvJKqjSbZLzMeta?.alias || [],
    redirect: indexvJKqjSbZLzMeta?.redirect,
    component: () => import("/app/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: filtersThvRgz34YJMeta?.name ?? "category-filters",
    path: filtersThvRgz34YJMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/category/filters",
    meta: filtersThvRgz34YJMeta || {},
    alias: filtersThvRgz34YJMeta?.alias || [],
    redirect: filtersThvRgz34YJMeta?.redirect,
    component: () => import("/app/pages/category/filters.vue").then(m => m.default || m)
  },
  {
    name: indexTKse6KvRRRMeta?.name ?? "category",
    path: indexTKse6KvRRRMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/category",
    meta: indexTKse6KvRRRMeta || {},
    alias: indexTKse6KvRRRMeta?.alias || [],
    redirect: indexTKse6KvRRRMeta?.redirect,
    component: () => import("/app/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: checkoutLpmDViNIqPMeta?.name ?? "checkout",
    path: checkoutLpmDViNIqPMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/checkout",
    meta: checkoutLpmDViNIqPMeta || {},
    alias: checkoutLpmDViNIqPMeta?.alias || [],
    redirect: checkoutLpmDViNIqPMeta?.redirect,
    component: () => import("/app/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: emptyJ3xHFAFI9fMeta?.name ?? "empty",
    path: emptyJ3xHFAFI9fMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/empty",
    meta: emptyJ3xHFAFI9fMeta || {},
    alias: emptyJ3xHFAFI9fMeta?.alias || [],
    redirect: emptyJ3xHFAFI9fMeta?.redirect,
    component: () => import("/app/pages/empty.vue").then(m => m.default || m)
  },
  {
    name: errors5uvLjYG1s0Meta?.name ?? "errors",
    path: errors5uvLjYG1s0Meta?.path ?? "/:locale(ru|en|uk|kz|en)?/errors",
    meta: errors5uvLjYG1s0Meta || {},
    alias: errors5uvLjYG1s0Meta?.alias || [],
    redirect: errors5uvLjYG1s0Meta?.redirect,
    component: () => import("/app/pages/errors.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: noveltydHIUSu1FCUMeta?.name ?? "novelty",
    path: noveltydHIUSu1FCUMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/novelty",
    meta: noveltydHIUSu1FCUMeta || {},
    alias: noveltydHIUSu1FCUMeta?.alias || [],
    redirect: noveltydHIUSu1FCUMeta?.redirect,
    component: () => import("/app/pages/novelty.vue").then(m => m.default || m)
  },
  {
    name: opthQTr7D4tQHMeta?.name ?? "opt",
    path: opthQTr7D4tQHMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/opt",
    meta: opthQTr7D4tQHMeta || {},
    alias: opthQTr7D4tQHMeta?.alias || [],
    redirect: opthQTr7D4tQHMeta?.redirect,
    component: () => import("/app/pages/opt.vue").then(m => m.default || m)
  },
  {
    name: about_46htmlulSueykfEnMeta?.name ?? "pages-about.html",
    path: about_46htmlulSueykfEnMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/pages/about.html",
    meta: about_46htmlulSueykfEnMeta || {},
    alias: about_46htmlulSueykfEnMeta?.alias || [],
    redirect: about_46htmlulSueykfEnMeta?.redirect,
    component: () => import("/app/pages/pages/about.html.vue").then(m => m.default || m)
  },
  {
    name: bestsellers_46htmlyj2uwVjm8nMeta?.name ?? "pages-bestsellers.html",
    path: bestsellers_46htmlyj2uwVjm8nMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/pages/bestsellers.html",
    meta: bestsellers_46htmlyj2uwVjm8nMeta || {},
    alias: bestsellers_46htmlyj2uwVjm8nMeta?.alias || [],
    redirect: bestsellers_46htmlyj2uwVjm8nMeta?.redirect,
    component: () => import("/app/pages/pages/bestsellers.html.vue").then(m => m.default || m)
  },
  {
    name: charityFkuUSG22zSMeta?.name ?? "pages-charity",
    path: charityFkuUSG22zSMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/pages/charity",
    meta: charityFkuUSG22zSMeta || {},
    alias: charityFkuUSG22zSMeta?.alias || [],
    redirect: charityFkuUSG22zSMeta?.redirect,
    component: () => import("/app/pages/pages/charity.vue").then(m => m.default || m)
  },
  {
    name: contacts_46htmlD8A6XnDtdtMeta?.name ?? "pages-contacts.html",
    path: contacts_46htmlD8A6XnDtdtMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/pages/contacts.html",
    meta: contacts_46htmlD8A6XnDtdtMeta || {},
    alias: contacts_46htmlD8A6XnDtdtMeta?.alias || [],
    redirect: contacts_46htmlD8A6XnDtdtMeta?.redirect,
    component: () => import("/app/pages/pages/contacts.html.vue").then(m => m.default || m)
  },
  {
    name: corporate_46htmliJ6FLPij8OMeta?.name ?? "pages-corporate.html",
    path: corporate_46htmliJ6FLPij8OMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/pages/corporate.html",
    meta: corporate_46htmliJ6FLPij8OMeta || {},
    alias: corporate_46htmliJ6FLPij8OMeta?.alias || [],
    redirect: corporate_46htmliJ6FLPij8OMeta?.redirect,
    component: () => import("/app/pages/pages/corporate.html.vue").then(m => m.default || m)
  },
  {
    name: delivery_46htmlucMSYyvLhQMeta?.name ?? "pages-delivery.html",
    path: delivery_46htmlucMSYyvLhQMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/pages/delivery.html",
    meta: delivery_46htmlucMSYyvLhQMeta || {},
    alias: delivery_46htmlucMSYyvLhQMeta?.alias || [],
    redirect: delivery_46htmlucMSYyvLhQMeta?.redirect,
    component: () => import("/app/pages/pages/delivery.html.vue").then(m => m.default || m)
  },
  {
    name: for_lessors_46htmlLlnorFbRclMeta?.name ?? "pages-for_lessors.html",
    path: for_lessors_46htmlLlnorFbRclMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/pages/for_lessors.html",
    meta: for_lessors_46htmlLlnorFbRclMeta || {},
    alias: for_lessors_46htmlLlnorFbRclMeta?.alias || [],
    redirect: for_lessors_46htmlLlnorFbRclMeta?.redirect,
    component: () => import("/app/pages/pages/for_lessors.html.vue").then(m => m.default || m)
  },
  {
    name: franchise_46htmlIfFe2M2JIdMeta?.name ?? "pages-franchise.html",
    path: franchise_46htmlIfFe2M2JIdMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/pages/franchise.html",
    meta: franchise_46htmlIfFe2M2JIdMeta || {},
    alias: franchise_46htmlIfFe2M2JIdMeta?.alias || [],
    redirect: franchise_46htmlIfFe2M2JIdMeta?.redirect,
    component: () => import("/app/pages/pages/franchise.html.vue").then(m => m.default || m)
  },
  {
    name: legal_46htmlBwckzuaJlGMeta?.name ?? "pages-legal.html",
    path: legal_46htmlBwckzuaJlGMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/pages/legal.html",
    meta: legal_46htmlBwckzuaJlGMeta || {},
    alias: legal_46htmlBwckzuaJlGMeta?.alias || [],
    redirect: legal_46htmlBwckzuaJlGMeta?.redirect,
    component: () => import("/app/pages/pages/legal.html.vue").then(m => m.default || m)
  },
  {
    name: map_46htmlEheqGURPosMeta?.name ?? "pages-map.html",
    path: map_46htmlEheqGURPosMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/pages/map.html",
    meta: map_46htmlEheqGURPosMeta || {},
    alias: map_46htmlEheqGURPosMeta?.alias || [],
    redirect: map_46htmlEheqGURPosMeta?.redirect,
    component: () => import("/app/pages/pages/map.html.vue").then(m => m.default || m)
  },
  {
    name: oneday_46htmlFHe5ivHFjWMeta?.name ?? "pages-oneday.html",
    path: oneday_46htmlFHe5ivHFjWMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/pages/oneday.html",
    meta: oneday_46htmlFHe5ivHFjWMeta || {},
    alias: oneday_46htmlFHe5ivHFjWMeta?.alias || [],
    redirect: oneday_46htmlFHe5ivHFjWMeta?.redirect,
    component: () => import("/app/pages/pages/oneday.html.vue").then(m => m.default || m)
  },
  {
    name: suppliers_46htmlnJJ5lSDxSWMeta?.name ?? "pages-suppliers.html",
    path: suppliers_46htmlnJJ5lSDxSWMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/pages/suppliers.html",
    meta: suppliers_46htmlnJJ5lSDxSWMeta || {},
    alias: suppliers_46htmlnJJ5lSDxSWMeta?.alias || [],
    redirect: suppliers_46htmlnJJ5lSDxSWMeta?.redirect,
    component: () => import("/app/pages/pages/suppliers.html.vue").then(m => m.default || m)
  },
  {
    name: vacancyrIJfFt9QeoMeta?.name ?? "pages-vacancy",
    path: vacancyrIJfFt9QeoMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/pages/vacancy",
    meta: vacancyrIJfFt9QeoMeta || {},
    alias: vacancyrIJfFt9QeoMeta?.alias || [],
    redirect: vacancyrIJfFt9QeoMeta?.redirect,
    component: () => import("/app/pages/pages/vacancy.vue").then(m => m.default || m)
  },
  {
    name: warranty_46htmljYk8XR3unzMeta?.name ?? "pages-warranty.html",
    path: warranty_46htmljYk8XR3unzMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/pages/warranty.html",
    meta: warranty_46htmljYk8XR3unzMeta || {},
    alias: warranty_46htmljYk8XR3unzMeta?.alias || [],
    redirect: warranty_46htmljYk8XR3unzMeta?.redirect,
    component: () => import("/app/pages/pages/warranty.html.vue").then(m => m.default || m)
  },
  {
    name: private_officeiJfgU5aBGwMeta?.name ?? undefined,
    path: private_officeiJfgU5aBGwMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/private_office",
    meta: private_officeiJfgU5aBGwMeta || {},
    alias: private_officeiJfgU5aBGwMeta?.alias || [],
    redirect: private_officeiJfgU5aBGwMeta?.redirect,
    component: () => import("/app/pages/private_office.vue").then(m => m.default || m),
    children: [
  {
    name: bonuses0zC88rvptiMeta?.name ?? "private_office-bonuses",
    path: bonuses0zC88rvptiMeta?.path ?? "bonuses",
    meta: bonuses0zC88rvptiMeta || {},
    alias: bonuses0zC88rvptiMeta?.alias || [],
    redirect: bonuses0zC88rvptiMeta?.redirect,
    component: () => import("/app/pages/private_office/bonuses.vue").then(m => m.default || m)
  },
  {
    name: index3CKdqKlWQtMeta?.name ?? "private_office",
    path: index3CKdqKlWQtMeta?.path ?? "",
    meta: index3CKdqKlWQtMeta || {},
    alias: index3CKdqKlWQtMeta?.alias || [],
    redirect: index3CKdqKlWQtMeta?.redirect,
    component: () => import("/app/pages/private_office/index.vue").then(m => m.default || m)
  },
  {
    name: my_45companyenFydBlYDiMeta?.name ?? "private_office-my-company",
    path: my_45companyenFydBlYDiMeta?.path ?? "my-company",
    meta: my_45companyenFydBlYDiMeta || {},
    alias: my_45companyenFydBlYDiMeta?.alias || [],
    redirect: my_45companyenFydBlYDiMeta?.redirect,
    component: () => import("/app/pages/private_office/my-company.vue").then(m => m.default || m)
  },
  {
    name: ordersmn7HQhwEdGMeta?.name ?? "private_office-orders",
    path: ordersmn7HQhwEdGMeta?.path ?? "orders",
    meta: ordersmn7HQhwEdGMeta || {},
    alias: ordersmn7HQhwEdGMeta?.alias || [],
    redirect: ordersmn7HQhwEdGMeta?.redirect,
    component: () => import("/app/pages/private_office/orders.vue").then(m => m.default || m)
  },
  {
    name: profileW0kWFDGH6xMeta?.name ?? "private_office-profile",
    path: profileW0kWFDGH6xMeta?.path ?? "profile",
    meta: profileW0kWFDGH6xMeta || {},
    alias: profileW0kWFDGH6xMeta?.alias || [],
    redirect: profileW0kWFDGH6xMeta?.redirect,
    component: () => import("/app/pages/private_office/profile.vue").then(m => m.default || m)
  },
  {
    name: purchasesjhjdgKy1dWMeta?.name ?? "private_office-purchases",
    path: purchasesjhjdgKy1dWMeta?.path ?? "purchases",
    meta: purchasesjhjdgKy1dWMeta || {},
    alias: purchasesjhjdgKy1dWMeta?.alias || [],
    redirect: purchasesjhjdgKy1dWMeta?.redirect,
    component: () => import("/app/pages/private_office/purchases.vue").then(m => m.default || m)
  },
  {
    name: review_45bonusesTHgtYoervdMeta?.name ?? "private_office-review-bonuses",
    path: review_45bonusesTHgtYoervdMeta?.path ?? "review-bonuses",
    meta: review_45bonusesTHgtYoervdMeta || {},
    alias: review_45bonusesTHgtYoervdMeta?.alias || [],
    redirect: review_45bonusesTHgtYoervdMeta?.redirect,
    component: () => import("/app/pages/private_office/review-bonuses.vue").then(m => m.default || m)
  },
  {
    name: reviewsgQxFaXnNrwMeta?.name ?? "private_office-reviews",
    path: reviewsgQxFaXnNrwMeta?.path ?? "reviews",
    meta: reviewsgQxFaXnNrwMeta || {},
    alias: reviewsgQxFaXnNrwMeta?.alias || [],
    redirect: reviewsgQxFaXnNrwMeta?.redirect,
    component: () => import("/app/pages/private_office/reviews.vue").then(m => m.default || m)
  },
  {
    name: saved_45articlesfEZJtACz9MMeta?.name ?? "private_office-saved-articles",
    path: saved_45articlesfEZJtACz9MMeta?.path ?? "saved-articles",
    meta: saved_45articlesfEZJtACz9MMeta || {},
    alias: saved_45articlesfEZJtACz9MMeta?.alias || [],
    redirect: saved_45articlesfEZJtACz9MMeta?.redirect,
    component: () => import("/app/pages/private_office/saved-articles.vue").then(m => m.default || m)
  },
  {
    name: wishlist_46phpEHmfw0BohOMeta?.name ?? "private_office-wishlist.php",
    path: wishlist_46phpEHmfw0BohOMeta?.path ?? "wishlist.php",
    meta: wishlist_46phpEHmfw0BohOMeta || {},
    alias: wishlist_46phpEHmfw0BohOMeta?.alias || [],
    redirect: wishlist_46phpEHmfw0BohOMeta?.redirect,
    component: () => import("/app/pages/private_office/wishlist.php.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexrLz3LKieQqMeta?.name ?? undefined,
    path: indexrLz3LKieQqMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/products/:slug()",
    meta: indexrLz3LKieQqMeta || {},
    alias: indexrLz3LKieQqMeta?.alias || [],
    redirect: indexrLz3LKieQqMeta?.redirect,
    component: () => import("/app/pages/products/[slug]/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexsLN0z2PK5UMeta?.name ?? "products-slug-index",
    path: indexsLN0z2PK5UMeta?.path ?? "",
    meta: indexsLN0z2PK5UMeta || {},
    alias: indexsLN0z2PK5UMeta?.alias || [],
    redirect: indexsLN0z2PK5UMeta?.redirect,
    component: () => import("/app/pages/products/[slug]/index/index.vue").then(m => m.default || m)
  },
  {
    name: reviewsiZDsgWhRwUMeta?.name ?? "products-slug-index-reviews",
    path: reviewsiZDsgWhRwUMeta?.path ?? "reviews",
    meta: reviewsiZDsgWhRwUMeta || {},
    alias: reviewsiZDsgWhRwUMeta?.alias || [],
    redirect: reviewsiZDsgWhRwUMeta?.redirect,
    component: () => import("/app/pages/products/[slug]/index/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: similar2t0l4jX5VSMeta?.name ?? "products-slug-similar",
    path: similar2t0l4jX5VSMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/products/:slug()/similar",
    meta: similar2t0l4jX5VSMeta || {},
    alias: similar2t0l4jX5VSMeta?.alias || [],
    redirect: similar2t0l4jX5VSMeta?.redirect,
    component: () => import("/app/pages/products/[slug]/similar.vue").then(m => m.default || m)
  },
  {
    name: testL3AVDvhyXeMeta?.name ?? "test",
    path: testL3AVDvhyXeMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/test",
    meta: testL3AVDvhyXeMeta || {},
    alias: testL3AVDvhyXeMeta?.alias || [],
    redirect: testL3AVDvhyXeMeta?.redirect,
    component: () => import("/app/pages/test.vue").then(m => m.default || m)
  },
  {
    name: indexTKse6KvRRRMeta?.name ?? "category-index",
    path: indexTKse6KvRRRMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/:category",
    meta: indexTKse6KvRRRMeta || {},
    alias: indexTKse6KvRRRMeta?.alias || [],
    redirect: indexTKse6KvRRRMeta?.redirect,
    component: () => import("/app/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: filtersThvRgz34YJMeta?.name ?? "category-filters",
    path: filtersThvRgz34YJMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/:category/:filters(.+)",
    meta: filtersThvRgz34YJMeta || {},
    alias: filtersThvRgz34YJMeta?.alias || [],
    redirect: filtersThvRgz34YJMeta?.redirect,
    component: () => import("/app/pages/category/filters.vue").then(m => m.default || m)
  },
  {
    name: private_officeiJfgU5aBGwMeta?.name ?? "wishlist-layout",
    path: private_officeiJfgU5aBGwMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/wishlist.php",
    meta: private_officeiJfgU5aBGwMeta || {},
    alias: private_officeiJfgU5aBGwMeta?.alias || [],
    redirect: private_officeiJfgU5aBGwMeta?.redirect,
    component: () => import("/app/pages/private_office.vue").then(m => m.default || m),
    children: [
  {
    name: wishlist_46phpEHmfw0BohOMeta?.name ?? "private_office-wishlist.php",
    path: wishlist_46phpEHmfw0BohOMeta?.path ?? "",
    meta: wishlist_46phpEHmfw0BohOMeta || {},
    alias: wishlist_46phpEHmfw0BohOMeta?.alias || [],
    redirect: wishlist_46phpEHmfw0BohOMeta?.redirect,
    component: () => import("/app/pages/private_office/wishlist.php.vue").then(m => m.default || m)
  }
]
  },
  {
    name: emptyJ3xHFAFI9fMeta?.name ?? "investors",
    path: emptyJ3xHFAFI9fMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/pages/for_investors.html",
    meta: emptyJ3xHFAFI9fMeta || {},
    alias: emptyJ3xHFAFI9fMeta?.alias || [],
    redirect: emptyJ3xHFAFI9fMeta?.redirect,
    component: () => import("/app/pages/empty.vue").then(m => m.default || m)
  },
  {
    name: emptyJ3xHFAFI9fMeta?.name ?? "rush_buy",
    path: emptyJ3xHFAFI9fMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/rush_buy/",
    meta: emptyJ3xHFAFI9fMeta || {},
    alias: emptyJ3xHFAFI9fMeta?.alias || [],
    redirect: emptyJ3xHFAFI9fMeta?.redirect,
    component: () => import("/app/pages/empty.vue").then(m => m.default || m)
  },
  {
    name: emptyJ3xHFAFI9fMeta?.name ?? "rush_buy_reviews",
    path: emptyJ3xHFAFI9fMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/rush_buy/reviews/",
    meta: emptyJ3xHFAFI9fMeta || {},
    alias: emptyJ3xHFAFI9fMeta?.alias || [],
    redirect: emptyJ3xHFAFI9fMeta?.redirect,
    component: () => import("/app/pages/empty.vue").then(m => m.default || m)
  },
  {
    name: emptyJ3xHFAFI9fMeta?.name ?? "reg_partnership",
    path: emptyJ3xHFAFI9fMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/pages/reg_partnership.html",
    meta: emptyJ3xHFAFI9fMeta || {},
    alias: emptyJ3xHFAFI9fMeta?.alias || [],
    redirect: emptyJ3xHFAFI9fMeta?.redirect,
    component: () => import("/app/pages/empty.vue").then(m => m.default || m)
  },
  {
    name: emptyJ3xHFAFI9fMeta?.name ?? "wine_tasting",
    path: emptyJ3xHFAFI9fMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/pages/wine_tasting.html",
    meta: emptyJ3xHFAFI9fMeta || {},
    alias: emptyJ3xHFAFI9fMeta?.alias || [],
    redirect: emptyJ3xHFAFI9fMeta?.redirect,
    component: () => import("/app/pages/empty.vue").then(m => m.default || m)
  },
  {
    name: emptyJ3xHFAFI9fMeta?.name ?? "articles",
    path: emptyJ3xHFAFI9fMeta?.path ?? "/:locale(ru|en|uk|kz|en)?/articles/",
    meta: emptyJ3xHFAFI9fMeta || {},
    alias: emptyJ3xHFAFI9fMeta?.alias || [],
    redirect: emptyJ3xHFAFI9fMeta?.redirect,
    component: () => import("/app/pages/empty.vue").then(m => m.default || m)
  }
]