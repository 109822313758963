import { defineNuxtPlugin } from '#app'
import { createNotivue } from 'notivue'

import 'notivue/notification.css'
import 'notivue/animations.css'

export default defineNuxtPlugin({
  name: 'nuxt-notify',
  setup (nuxtApp) {
    const notify = createNotivue({
      position: 'top-center',
      limit: 4,
      enqueue: true,
      avoidDuplicates: true,
      notifications: {
        global: {
          duration: 2500
        }
      }
    })

    nuxtApp.vueApp.use(notify)
  }
})
