import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('custom-show', {
    mounted (el, binding) {
      if (!binding.value) {
        el.style.display = 'none'
      } else if (el.style.length === 1 && el.style[0] === 'display') {
        el.removeAttribute('style')
      } else {
        el.style.display = ''
      }
    },
    updated (el, binding) {
      if (!binding.value) {
        el.style.display = 'none'
      } else {
        el.style.length === 1 ? el.removeAttribute('style') : el.style.display = ''
      }
    },
    getSSRProps (binding) {
      return binding.value ? {} : { style: 'display: none' }
    }
  })
})
