import { useShopStore } from '@/stores/shop'
import { useConfig } from '@/stores/config'

export const setModalShopOptions = () => {
  const shopStore = useShopStore()
  const { currentRegion } = useConfig()

  shopStore.mapSettings.value.zoom = currentRegion.value?.city?.mapSettings?.zoom ?? 11
  shopStore.mapSettings.value.coordinates = [
    currentRegion.value?.city?.mapSettings?.coordinates?.longitude ?? 37.64,
    currentRegion.value?.city?.mapSettings?.coordinates?.latitude ?? 55.76
  ]
}
