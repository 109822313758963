import { useLocalization } from '#imports'
import { defineNuxtPlugin, useRuntimeConfig } from '#app'

import type { Router } from 'vue-router'

export default defineNuxtPlugin(async () => {
  const options = useRuntimeConfig().public.localization
  const router: Router = useRouter()

  const { setLocale, getTranslator } = useLocalization()

  router.beforeEach(async (to) => {
    const paramsLocale = to.params.locale?.toString()

    if (paramsLocale && options.locales.includes(paramsLocale)) {
      if (import.meta.server) {
        await setLocale(paramsLocale)
      } else {
        setLocale(paramsLocale)
      }
    }
  })

  return { provide: { t: getTranslator() } }
})
