import { useCustomerStore } from '@/stores/customer'
import { setModalShopOptions } from '@/utils/modal-shop-options'
import { useCartStore } from '@/stores/cart'
import { useFavoritesStore } from '@/stores/favorites'

type AppSetupReturnType = {
  serverSetup: () => Promise<void>
  clientSetup: (isCartPage?: boolean) => void
}

export const appSetup = (): AppSetupReturnType => {
  const serverSetup: AppSetupReturnType['serverSetup'] = async () => {
    setModalShopOptions()
  }

  const clientSetup: AppSetupReturnType['clientSetup'] = async (isCartPage?: boolean) => {
    if (process.client) {
      const cartStore = useCartStore()
      const favoritesStore = useFavoritesStore()
      const customerStore = useCustomerStore()

      await Promise.all([
        customerStore.initCustomer(),
        cartStore.initCart(isCartPage),
        favoritesStore.getWishListProductIds()
      ])

      const route = useRoute()

      if (
        (customerStore.isVerified.value && route.name === 'login') ||
        (!customerStore.isVerified.value && route.name === 'private_office-profile')
      ) {
        useRouter().replace('/')
      }
    }
  }

  return { serverSetup, clientSetup }
}
