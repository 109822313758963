import { defineNuxtPlugin } from '#app'
import { useLocalization, useHydrationRef } from '#imports'
import { useConfig } from '@/stores/config'

export default defineNuxtPlugin({
  name: 'app-config',
  order: 2,
  async setup (nuxtApp) {
    const { getRegions, getCurrentRegion, currentRegion } = useConfig()
    const { setLocale, loadDynamicMessages, setMessages } = useLocalization()

    const currentLocale = useHydrationRef('current-locale', 'ru')

    const host = useRequestURL().host

    if (import.meta.client) {
      const messages = await loadDynamicMessages(currentLocale.value ?? 'ru')

      setMessages(messages)
    } else {
      try {
        const regions = await getRegions()
        currentRegion.value = getCurrentRegion(regions, host)

        if (currentRegion.value) {
          currentLocale.value = currentRegion.value.defaultLocale

          await setLocale(currentRegion.value.defaultLocale)
        } else {
          // fallback
          await setLocale('ru')
        }

        nuxtApp.ssrContext?.event.node.res.setHeader('Content-Language', currentRegion.value?.defaultLocale)
      } catch (err) {
        await setLocale('ru')
      }
    }
  }
})
