import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import { useLocalization } from '#imports'

export type YMapsPluginType = {
  isInit: boolean
  isLoaded: boolean
  isReady: boolean
  instance?: typeof window.ymaps
  createMap(): Promise<typeof window.ymaps | undefined>
  loadMap(): Promise<typeof window.ymaps | undefined>
  appendScript(): Promise<typeof window.ymaps>
}

export default defineNuxtPlugin({
  name: 'nuxt-yandex-maps',
  async setup () {
    const options = useRuntimeConfig()
    let promise: Promise<any> | undefined = undefined

    const ymaps: YMapsPluginType = {
      isInit: false,
      isLoaded: false,
      isReady: false,
      instance: undefined,
      async createMap () {
        if (promise) {
          await promise

          promise = undefined
        } else {
          await this.loadMap()
        }

        return this.instance
      },

      async loadMap () {
        if (!this.isLoaded) {
          this.instance = await this.appendScript()
        }

        return this.instance
      },

      appendScript () {
        const key = options.public.ymapsApiKey
        const { locale } = useLocalization()

        promise = new Promise((resolve, reject) => {
          const script = document.createElement('script')
          const lang = locale.value === 'en' ? 'en_US' : 'ru_RU'

          script.setAttribute(
            'src',
            `https://api-maps.yandex.ru/v3/?apikey=${key}&lang=${lang}`
          )
          script.setAttribute('async', '')
          script.setAttribute('defer', '')

          if (!this.isInit) {
            document.head.appendChild(script)
            this.isInit = true
          }

          script.onload = () => {
            this.isLoaded = true

            ymaps3.ready.then(() => {
              this.isReady = true
              resolve(ymaps3)
            })
          }

          script.onerror = (e) => {
            reject(e)
          }
        })

        return promise
      }
    }

    return { provide: { ymaps } }
  }
})
