import { defineNuxtPlugin } from '#app'
import { useAdmin, useModals } from '#imports'
import { defineAsyncComponent } from 'vue'

export default defineNuxtPlugin({
  name: 'nuxt-admin',
  async setup (nuxtApp) {
    const { defineModals } = useModals()
    const { isAdmin } = useAdmin()

    if (isAdmin.value) {
      useAdmin().setLocale('ru')
      defineModals({
        'admin-seo-tags': defineAsyncComponent(() => /* @vite-ignore */ import('./modals/seotags-modal.vue')),
        'admin-banners': defineAsyncComponent(() => /* @vite-ignore */ import('./modals/banners-modal.vue')),
        'admin-actual-category': defineAsyncComponent(() => /* @vite-ignore */ import('./modals/actual-category-modal.vue')),
        'admin-carousel-offer': defineAsyncComponent(() => /* @vite-ignore */ import('./modals/carousel-offer-modal.vue'))
      })
    }

    nuxtApp.hooks.hook('localization:locale-changed', (locale) => {
      if (isAdmin.value) {
        useAdmin().setLocale(locale)
      }
    })
  }
})
