export class UnauthorizedError extends Error {
  code?: number

  constructor (name: string, message: string, code?: number) {
    super(message)

    this.name = name
    this.code = code
  }
}
