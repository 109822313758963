import type { RouterConfig } from '@nuxt/schema'
import { nextTick } from 'vue'
import { useNuxtApp } from '#app'

export default <RouterConfig>{
  scrollBehavior: (to, from, savedPosition) => {
    const nuxtApp = useNuxtApp()

    if (to.hash || from.hash) {
      return false
    }

    // "from" page position is at the end of the page
    if (document.body.offsetHeight <= window.scrollY + 2 * screen.height) {
      return { top: 0 }
    }

    if (
      !from.name ||
      (to.name === 'category-filters' && String(from.name ?? '').includes('category'))
    ) { return false }

    return new Promise((resolve) => {
      nuxtApp.hooks.hookOnce('page:finish', async () => {
        await nextTick()

        const position = {
          top: !String(to.name ?? '').includes('products-slug-index') && !String(to.name ?? '').includes('login')
            ? savedPosition?.top ?? 0
            : 0
        }

        // if the page height is less than the saved position
        if (document.body.offsetHeight < position.top + screen.height) {
          const timeout = setTimeout(() => {
            clearTimeout(timeout)
            resolve(position)
          }, 300)
        } else {
          resolve(position)
        }
      })
    })
  }
}
