import cloneDeep from 'clone-deep'
import { toRef } from 'vue'
import { useNuxtApp } from '#app'
import { useAPI, useSharedPromise, useHydrationStore } from '#imports'
import { useCustomerStore } from '@/stores/customer'

import type { WishListItem } from '@/modules/nuxt-api/models/WishList'

export type SortingOption = {
  target: string
  direction?: number
}

type State = {
  products: WishListItem[]
  productIds: number[]
  productLoading: boolean
  inProcess: boolean

  filterCodes: string[]
  currentFilter: string
}

export function useFavoritesStore () {
  const nuxtApp = useNuxtApp()
  const api = useAPI()
  const state = useHydrationStore<State>('favorites-store', {
    products: [],
    productIds: [],
    productLoading: false,
    inProcess: false,

    filterCodes: [],
    currentFilter: 'all'
  }, { disableHydration: true })

  async function getWishListProducts () {
    const { getProductFromWishList } = api.wishlist()
    state.value.productLoading = true

    try {
      state.value.products = await getProductFromWishList()

      if (state.value.products) {
        state.value.productIds = state.value.products.map(item => item.product?.id).filter(id => id !== undefined) as number[]
        setFilterCodes(state.value.products)
      }
    } finally {
      state.value.productLoading = false
    }

    return state.value.products
  }

  async function getWishListProductIds (idsList?: string) {
    let productIdsList

    if (!idsList) {
      const { getProductIdsFromWishList } = api.wishlist()
      productIdsList = await getProductIdsFromWishList()
    } else {
      productIdsList = JSON.parse(idsList)
    }

    if (productIdsList) {
      state.value.productIds = cloneDeep(productIdsList)
    }
  }

  async function addProductToWishList (id: number) {
    await nuxtApp.runWithContext(() => useSharedPromise('wishlist-add-product', async () => {
      if (state.value.inProcess) { return }

      state.value.inProcess = true
      const { addProductToWishList } = api.wishlist()

      try {
        await addProductToWishList(id)

        if (useCustomerStore().isVerified.value) {
          getWishListProductIds()
        } else {
          state.value.productIds.push(id)
        }
      } finally {
        state.value.inProcess = false
      }
    }))
  }

  async function removeProductFromWishList (id: number, isWishList: boolean | undefined = false) {
    await nuxtApp.runWithContext(() => useSharedPromise('wishlist-remove-product', async () => {
      if (state.value.inProcess) { return }

      state.value.inProcess = true
      const { removeProductFromWishList } = api.wishlist()

      try {
        await removeProductFromWishList(id)

        if (isWishList) {
          state.value.productIds = state.value.productIds.filter((productId: number) => productId !== id)
        } else if (useCustomerStore().isVerified.value) {
          await getWishListProductIds()
        } else {
          state.value.productIds = state.value.productIds.filter((productId: number) => productId !== id)
        }
      } finally {
        state.value.inProcess = false
      }
    }))
  }

  function isProductFavorite (id?: number) {
    if (process.server || id === undefined) {
      return false
    }
    return !!state.value.productIds?.find((productId: number) => productId === id)
  }

  async function toggleProductFavorite (id?: number, isWishList = false) {
    if (id === undefined) { return }

    isProductFavorite(id)
      ? await removeProductFromWishList(id, isWishList)
      : await addProductToWishList(id)
  }

  function setFilterCodes (wishListItems: WishListItem[]) {
    state.value.filterCodes = [
      ...Array.from(new Set(wishListItems
        .map(item => item.productCategory)
        .filter(code => code !== undefined) as string[]
      ))
    ]

    if (!state.value.filterCodes.includes(state.value.currentFilter)) {
      state.value.currentFilter = 'all'
    }
  }

  function setData (data: WishListItem[]) {
    state.value.products = data
    state.value.productIds = state.value.products.map(item => item.product?.id).filter(id => id !== undefined) as number[]
  }

  async function clearWishlist () {
    const { clearProductWishList } = api.wishlist()
    await clearProductWishList()
    state.value.products = []
    state.value.productIds = []
  }

  return {
    getWishListProducts,
    getWishListProductIds,
    addProductToWishList,
    removeProductFromWishList,
    isProductFavorite,
    toggleProductFavorite,
    setData,
    clearWishlist,

    products: toRef(state.value, 'products'),
    productIds: toRef(state.value, 'productIds'),
    productLoading: toRef(state.value, 'productLoading'),
    filterCodes: toRef(state.value, 'filterCodes'),
    currentFilter: toRef(state.value, 'currentFilter')
  }
}
