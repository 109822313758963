import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import type { RegionsEnvsConfig } from '@/types/regionEnvs'
import { useConfig } from '@/stores/config'

export default defineNuxtPlugin({
  name: 'nuxt-metrica',
  enforce: 'pre',
  order: 5,
  async setup () {
    const config = useRuntimeConfig()
    const { currentRegion } = useConfig()

    const analyticsRegionsJson = config.public.regionsEnvsJson as unknown as RegionsEnvsConfig
    const filteredEntries = Object.entries(analyticsRegionsJson)
      .filter(([id]) => parseInt(id) === currentRegion.value.id)

    config.public.regionsEnvsJson = Object.fromEntries(filteredEntries) as any
  }
})
