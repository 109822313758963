import type { CookieOptions, CookieRef } from '#app'
import { useCookie, useHydrationRef, watch } from '#imports'
import { useConfig } from '@/stores/config'

export const useStatefulCookie = <T = string>(name: string, options?: CookieOptions<T>): CookieRef<T> => {
  const cookie = useCookie<T>(name, { ...options, readonly: false })
  const state = useHydrationRef<T>(name, cookie.value)

  if (options) {
    watch(state, () => (cookie.value = state.value), { deep: true })
  }

  return state
}

export function useMainDomainCookie<T = string> (name: string, options: CookieOptions = {}) {
  const host = useRequestURL().host ?? ''
  const { currentRegion, testRegionSubdomain } = useConfig()

  let hostWithoutSubdomains = host.split(':')[0]

  if (testRegionSubdomain.value) {
    hostWithoutSubdomains = hostWithoutSubdomains.replace(testRegionSubdomain.value, '')
  } else if (currentRegion.value.subdomain) {
    hostWithoutSubdomains = hostWithoutSubdomains.replace(currentRegion.value.subdomain, '')
  }

  const expiresDate = new Date()
  const expiresDatePlusYear = new Date(expiresDate.setFullYear(expiresDate.getFullYear() + 1))

  return useCookie<T>(name, {
    expires: expiresDatePlusYear,
    domain: hostWithoutSubdomains,
    ...options,
    readonly: false
  })
}

export function useDomainCookie<T = string> (name: string, options: CookieOptions = {}) {
  const host = useRequestURL().host ?? ''

  const hostWithoutSubdomains = host.split(':')[0]

  const expiresDate = new Date()
  const expiresDatePlusYear = new Date(expiresDate.setFullYear(expiresDate.getFullYear() + 1))

  return useCookie<T>(name, {
    expires: expiresDatePlusYear,
    domain: hostWithoutSubdomains,
    ...options,
    readonly: false
  })
}
