import { Region as GRPCRegion } from '@winestyle/api-client/src/ts/api/region/v1/region_pb'
import { defineNuxtRouteMiddleware, createError, navigateTo } from '#app'
import { useConfig } from '@/stores/config'

import type { LocaleType } from '@/types/locale.d'

export default defineNuxtRouteMiddleware((to) => {
  const { currentRegion } = useConfig()

  if (to.params.locale && !currentRegion.value?.allowLocalesList?.includes(to.params.locale as LocaleType)) {
    throw createError({ statusCode: 404, statusMessage: 'Page Not Found' })
  }

  if (
    to.params.locale &&
    (
      currentRegion.value?.localeCodeType === GRPCRegion.LocaleCodeType.LOCALE_CODE_TYPE_IN_SUBDOMAIN ||
      to.params.locale === currentRegion.value?.defaultLocale
    )
  ) {
    return navigateTo(to.fullPath.replace(/^\/(en|ru)(\/|$)/i, '/'), { redirectCode: 301 })
  }
})
