import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin(() => {
})

type PluginMetricData = {
  src: string
  start: number
  end: number
  duration: number
}

declare module '#app' {
  interface RuntimeNuxtHooks {
    'plugin-metrics:log': (data: PluginMetricData) => void
  }
}
