import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import { useConfig } from '@/stores/config'
import type { RegionsEnvsConfig } from '@/types/regionEnvs'

const temp = (id: string) => `
    (function(w,d,s,l,i){
        w[l]=w[l]||[];
        w[l].push({
            'gtm.start': new Date().getTime(), event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${id}')`

declare global {
  interface Window {
    dataLayer: any
  }
}

export class GoogleTag {
  id = ''

  constructor (id: string) {
    this.id = id
  }

  event (name: string, data: any) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: name,
      ecommerce: {
        items: [{
          item_id: data.id
        }]
      }
    })
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { currentRegion } = useConfig()
  const analyticsRegionsJson = config.public.regionsEnvsJson as unknown as RegionsEnvsConfig
  const id = analyticsRegionsJson[currentRegion.value.id]?.gtmId as number | string

  if (!id) {
    return
  }

  const content = temp(id.toString())
  const google = new GoogleTag(id.toString())

  nuxtApp.hooks.hook('app:rendered', (ctx) => {
    const head = ctx.ssrContext?.head

    head?.push({
      script: [
        {
          type: 'text/partytown',
          innerHTML: content
        }
      ]
    })
  })
  nuxtApp.hooks.callHook('analytics:gtm:init')

  return {
    provide: {
      google
    }
  }
})
