import type { NuxtApp } from '#app'
import type { Message } from 'google-protobuf'
import type { Request, UnaryInterceptor, UnaryResponse } from 'grpc-web'
import type { ReqTypeWithResponseCases } from '../models/type-helpers/reqTypeWithResponseCases'
import type { ResTypeWithResponseCases } from '../models/type-helpers/resTypeWithResponseCases'
import type { useSentry } from '#imports'

import { useNotify } from '#imports'
import { GrpcError } from '../models/errors/grpc-error'
import { UnauthorizedError } from '../models/errors/unauthorized-error'
import { ServerError } from '../models/errors/server-error'
import { RedirectError } from '../models/errors/redirect-error'
import { SectionError } from '../models/errors/section-error'

type RequestMessage = ReqTypeWithResponseCases<Message>
type ResponseMessage = ResTypeWithResponseCases<Message>
type InterceptorRequest = Request<RequestMessage, ResponseMessage>
type InterceptorUnaryResponse = UnaryResponse<RequestMessage, ResponseMessage>

export class ErrorInterceptor implements UnaryInterceptor<Message, Message> {
  nuxtApp: NuxtApp
  sentry: ReturnType<typeof useSentry>

  constructor (sentry: any, nuxtApp: NuxtApp) {
    this.sentry = sentry
    this.nuxtApp = nuxtApp
  }

  async intercept (
    request: InterceptorRequest,
    invoker: (invokerRequest: InterceptorRequest) => Promise<InterceptorUnaryResponse>
  ): Promise<InterceptorUnaryResponse> {
    const responseCases = request.getRequestMessage().responseCases
    // const responseCheckValidation = request.getRequestMessage().checkValidation
    const responseCheckRedirect = request.getRequestMessage().checkRedirect
    const responseCheckProductNotFound = request.getRequestMessage().checkProductNotFound
    const responseCheckShopNotFound = request.getRequestMessage().checkShopNotFound
    const responseName = request.getMethodDescriptor().getName()

    const validCases = [
      responseCases?.DATA,
      responseCases?.RESPONSE_NOT_SET
    ]

    this.sentry.addBreadcrumb({
      category: 'api',
      message: 'Create request',
      data: {
        responseName,
        message: request.getRequestMessage().toObject()
      },
      level: 'info'
    })

    async function getResponse () {
      try {
        const response: InterceptorUnaryResponse = await invoker(request)

        return response
      } catch (err: any) {
        throw new GrpcError('ApiError: GRPC ERROR', err.message + ' (' + responseName + ')', err.code)
      }
    }

    // function checkResponseServerError (currentCase: number) {
    //   if ([2, 13].includes(currentCase)) {
    //     throw new ServerError('ApiError: SERVER ERROR', '[' + currentCase + '] ' + responseName, currentCase)
    //   }
    // }

    try {
      const response = await getResponse()
      const meta = response.getMetadata()
      const currentCase = response.getResponseMessage().getResponseCase()

      const sectionErrorCase = responseCases?.INVALID_SECTION_ERROR || -1
      const productNotFoundCase = responseCases?.PRODUCT_NOT_FOUND_ERROR || -1
      const shopNotFoundCase = responseCases?.SHOP_NOT_FOUND_ERROR || -1

      this.sentry.addBreadcrumb({
        category: 'api',
        message: 'Receive response',
        data: {
          responseName,
          currentCase,
          meta,
          message: response.getResponseMessage().toObject()
        },
        level: 'info'
      })

      if (responseCases) {
        if (currentCase === sectionErrorCase) {
          // @ts-expect-error
          const redirectPath = response.getResponseMessage().getInvalidSectionError?.().toObject?.()?.redirectLocation?.path

          if (responseCheckRedirect && currentCase === sectionErrorCase) {
            if (redirectPath) {
              throw new RedirectError('Redirect', redirectPath)
            }

            const error = new SectionError('ApiError: SECTION ERROR', responseName, currentCase)

            error.response = response?.getResponseMessage()

            throw error
          }

          return response
        }

        // if (meta['grpc-status']) {
        //   checkResponseServerError(parseInt(meta['grpc-status']))
        // }

        // @ts-expect-error
        if (response?.getResponseMessage().toObject().unauthorizedError) {
          throw new UnauthorizedError('UnauthorizedError', '[' + currentCase + '] ' + responseName, currentCase)
        }

        // @ts-expect-error
        if (response?.getResponseMessage().toObject().serverError) {
          throw new ServerError('ApiError: SERVER ERROR BODY', '[' + currentCase + '] ' + responseName, currentCase)
        }

        if ((responseCheckProductNotFound && currentCase === productNotFoundCase) || (responseCheckShopNotFound && currentCase === shopNotFoundCase)) {
          const error = new SectionError('ApiError: SECTION ERROR', responseName, currentCase)

          error.response = response?.getResponseMessage()

          throw error
        }

        if (!validCases.includes(currentCase)) {
          const error = new ServerError('ApiError: CASE ERROR', '[' + currentCase + '] ' + responseName, currentCase)

          error.response = response?.getResponseMessage()

          throw error
        }
      }

      return response
    } catch (err: any) {
      if (err instanceof RedirectError) {
        this.sentry.addBreadcrumb({
          category: 'api',
          message: 'Receive redirect',
          data: {
            responseName,
            redirectPath: err.redirectPath
          },
          level: 'info'
        })

        throw err
      }

      if (err.message.includes('timed out')) {
        await this.nuxtApp.runWithContext(async () => {
          throw showError({ message: 'Timed out', statusCode: 503, fatal: true })
        })
      }

      if (import.meta.client) {
        this.sentry.captureException(err)
      } else if (!(err instanceof SectionError)) {
        this.sentry.captureException(err)
      }

      this.sentry.addBreadcrumb({
        category: 'api',
        message: err.message,
        data: {
          responseName,
          responseMessage: err.response?.toObject()
        },
        level: 'error'
      })

      if (!responseName.includes('customer')) { // временное решение для скрытия алертов в запросах авторизации
        this.nuxtApp.runWithContext(() => {
          useNotify().error({
            title: err.name,
            message: err.message
          })
        })
      }

      throw err
    }
  }
}
