import { defineNuxtRouteMiddleware, navigateTo } from '#app'
import { useConfig } from '@/stores/config'

export default defineNuxtRouteMiddleware((to) => {
  const { currentRegion } = useConfig()

  // Редирект на главную из блога для регионов,
  // заглушка перед запуском пока не подготовлен блог

  if (currentRegion.value?.city?.code !== 'ru-mow') {
    if (to.path.includes('/pages/charity')) {
      throw createError({ statusCode: 404, message: 'Error', fatal: true })
    }

    if (/(\/|-)articles/i.test(to.path)) {
      return navigateTo({ path: '/' }, { redirectCode: 301 })
    }
  }
})
