import { defineNuxtPlugin } from '#app'
import { useAPI, useHydrationRef } from '#imports'
import { useMainDomainCookie, useDomainCookie } from '@/composables/cookie'
import { useDevice } from '@/composables/device'
import { useRedirect } from './composables'
import { useConfig } from '@/stores/config'

export default defineNuxtPlugin({
  name: 'nuxt-check-region',
  async setup (nuxt) {
    const redirectData = useHydrationRef('redirect-data')
    const remoteAddress = useHydrationRef('remote-address')
    const showCityAlert = useHydrationRef('city-alert', false)
    const cityAlertRegionId = useHydrationRef('city-alert-region-id')

    const { checkIfExistInRedirectCookie, addToRedirectCookie, checkCookieAndRedirect } = useRedirect()
    const { currentRegion, mainRegionId, getRegionIdByCountryCode, isMscRegion } = useConfig()
    const api = useAPI()

    if (!currentRegion.value.id) {
      return
    }

    const { isCrawler } = useDevice()

    if (isCrawler) {
      return
    }

    if (nuxt.ssrContext?.event.context.redirectData) {
      redirectData.value = nuxt.ssrContext?.event.context.redirectData
    }

    if (nuxt.ssrContext?.event.context.clientIp) {
      remoteAddress.value = nuxt.ssrContext?.event.context.clientIp
    }

    if (import.meta.client) {
      if (!isMscRegion.value) {
        const redirectedToFrom = useDomainCookie('redirected-to-from', {
          expires: new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
          maxAge: 0
        })
        redirectedToFrom.value = ''
      }

      if (Number(redirectData.value?.need_redirect)) {
        return onRedirect()
      }

      if (checkIfExistInRedirectCookie(currentRegion.value.id) || checkCookieAndRedirect()) {
        return
      }

      const expiresDate = new Date()
      expiresDate.setHours(expiresDate.getHours() + 5)

      const regionFromApi = useMainDomainCookie<{ id: number, countryCode?: string }>('region-by-ip', {
        expires: expiresDate
      })

      let allRegions = false

      if (!regionFromApi.value) {
        const customIp = useMainDomainCookie('customIp')
        const ipAddress = customIp.value || remoteAddress.value
        const data = await api.region().getRegionByClientIP(ipAddress)

        regionFromApi.value = {
          id: data?.regionId || getRegionIdByCountryCode(data?.countryIsoCode),
          countryCode: data?.countryIsoCode
        }

        // Москва и регионы
        if (!data?.regionId && (data?.countryIsoCode === 'ru' || !data?.countryIsoCode)) {
          allRegions = true
        }
      }

      // Модальное окно выбора страны
      const isFromAnotherCountry = regionFromApi.value.countryCode &&
        regionFromApi.value.countryCode in mainRegionId.value &&
        currentRegion.value.country?.code !== regionFromApi.value.countryCode
      if (isFromAnotherCountry && regionFromApi.value.id) {
        const { openModal } = useModals()

        await openModal('country-select', {
          redirectCountryId: regionFromApi.value.id,
          allRegions
        })

        return
      }

      // Сохраняем значение в куку, чтобы выводить "Москва и регионы"
      if (allRegions) {
        const expiresDate = new Date()
        expiresDate.setFullYear(expiresDate.getFullYear() + 3)
        const allRegions = useMainDomainCookie<number>('all-regions', { expires: expiresDate })
        allRegions.value = 1
      }

      // Если регион по IP совпадает с текущим регионом и с главным регионом в стране, то сохраняем регион и не выводим алерт
      const regionId = mainRegionId.value[currentRegion.value.country?.code ?? 'ru']
      if (regionFromApi.value.id === regionId && currentRegion.value.id === regionId) {
        addToRedirectCookie(currentRegion.value.id)
        return
      }

      cityAlertRegionId.value = regionFromApi.value.id
      showCityAlert.value = true
    }

    function onRedirect () {
      addToRedirectCookie(currentRegion.value.id, redirectData.value?.from_country)

      if (Number(redirectData.value?.all_regions)) {
        const expiresDate = new Date()
        expiresDate.setFullYear(expiresDate.getFullYear() + 3)
        const allRegions = useMainDomainCookie<number>('all-regions', { expires: expiresDate })

        allRegions.value = 1
      }

      if (
        redirectData.value?.no_confirm ||
        !redirectData.value?.from_country ||
        redirectData.value?.from_country === currentRegion.value.id) {
        return
      }

      showCityAlert.value = true
    }
  }
})
