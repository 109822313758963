import type { CookieSerializeOptions } from 'cookie-es'
import { serialize } from 'cookie-es'
import { useCookie, useNuxtApp } from '#app'

export const checkYMarketMark = (): void => {
  const referrer = process.server
    ? useNuxtApp().ssrContext?.event.node.req.headers.referer
    : document.referrer

  if (!referrer?.length) { return }

  const hosts = ['market.yandex.', 'market-click2.yandex.']
  const searchParams = ['frommarket', 'ymclid']

  const url = new URL(referrer)
  const isFromYMarket = hosts.some(el => url.hostname.includes(el)) ||
    searchParams.some(el => Object.keys(url.searchParams).includes(el))

  if (isFromYMarket) {
    const cookieOptions: CookieSerializeOptions = {
      maxAge: 108000,
      path: '/',
      sameSite: 'none'
    }

    const yMark = useCookie('FromYMarket', cookieOptions)

    if (yMark.value === '1' && process.client) {
      document.cookie = serialize('FromYMarket', '1', cookieOptions)

      return
    }

    yMark.value = '1'
  }
}
