import type { Message } from 'google-protobuf'
import type { Request, UnaryInterceptor, UnaryResponse } from 'grpc-web'
import type { NuxtApp } from '#app'

type InterceptorRequest = Request<Message, Message>
type InterceptorUnaryResponse = UnaryResponse<Message, Message>

export class NetworkInterceptor implements UnaryInterceptor<Message, Message> {
  nuxt: NuxtApp

  constructor (nuxt: NuxtApp) {
    this.nuxt = nuxt
  }

  intercept (
    request: InterceptorRequest,
    invoker: (invokerRequest: InterceptorRequest) => Promise<InterceptorUnaryResponse>
  ): Promise<InterceptorUnaryResponse> {
    this.nuxt.runWithContext(async () => {
      if (!window.navigator.onLine) {
        throw showError({ message: 'Network unavailable', statusCode: 503, fatal: true })
      }
    })

    return invoker(request)
  }
}
