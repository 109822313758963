<template>
  <Html :lang="locale">
    <NuxtLayout>
      <ALoader v-if="isLoading" class="page-loader" />
      <NuxtPage />
    </NuxtLayout>

    <!-- <DevlogsWrapper /> -->
  </Html>
</template>

<script setup lang="ts">
import {
  onUnmounted,
  onMounted
} from 'vue'
import { useRoute } from 'vue-router'
import { useLoadingIndicator, useLocalization } from '#imports'
import { appSetup } from '@/utils/app'

import ALoader from '@/components/atoms/Loader/ALoader.vue'

const route = useRoute()
const { locale } = useLocalization()
const { serverSetup, clientSetup } = appSetup()

const { isLoading, finish } = useLoadingIndicator({ throttle: 500 })

if (import.meta.server) {
  await serverSetup()
}

const handlePreloadError = () => {
  throw createError({ statusCode: 500 })
}
onMounted(() => {
  clientSetup(route.name?.toString?.()?.includes?.('cart'))

  const nuxtApp = useNuxtApp()
  nuxtApp.hook('page:start', () => finish({ force: true }))
  nuxtApp.hook('app:error', () => window.scrollTo(0, 0))

  window.addEventListener('vite:preloadError', handlePreloadError)
})
onUnmounted(() => {
  window.removeEventListener('vite:preloadError', handlePreloadError)
})
</script>

<style lang="postcss">
.page-loader.loader {
  --loader-top: calc(50vh - var(--spacer-2xs));

  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100vh;
  margin: 0;
}
</style>
