import { defineAsyncComponent } from 'vue'
import { defineNuxtPlugin } from '#app'
import { useModals } from '#imports'

export default defineNuxtPlugin({
  name: 'modals',
  setup () {
    const { defineModals, defineSidebars } = useModals()

    defineModals({
      'product-expert': defineAsyncComponent(() => /* @vite-ignore */ import('@/components/modals/product-expert.vue')),
      'product-image-preview': defineAsyncComponent(() => /* @vite-ignore */ import('@/components/modals/product-image-preview.vue')),
      'request-call': defineAsyncComponent(() => /* @vite-ignore */ import('@/components/modals/request-call.vue')),
      'shop-select': defineAsyncComponent(() => /* @vite-ignore */ import('@/components/modals/shops-modal.vue')),
      'city-select': defineAsyncComponent(() => /* @vite-ignore */ import('@/components/modals/city-modal.vue')),
      'country-select': defineAsyncComponent(() => /* @vite-ignore */ import('@/components/modals/country-modal.vue')),
      'sorting-select': defineAsyncComponent(() => /* @vite-ignore */ import('@/components/modals/sorting-modal.vue')),
      'filters-select': defineAsyncComponent(() => /* @vite-ignore */ import('@/components/modals/filters-modal.vue')),
      'search': defineAsyncComponent(() => /* @vite-ignore */ import('@/components/modals/search-modal.vue'))
    })

    defineSidebars({
      'burger': defineAsyncComponent(() => /* @vite-ignore */ import('@/components/business/header/HeaderBurger.vue'))
    })
  }
})
