import { toRef, ref } from 'vue'
import { useNuxtApp } from '#app'
import { useAPI, useHydrationStore, useSentry } from '#imports'
import { MIN_LAPTOP_WIDTH } from '@/composables/device'
import { useDebounceFn } from '@vueuse/core'
import type { Product } from '@winestyle/api-client/src/ts/api/catalog/v1/product_pb.js'
// import { useProductStore } from '@/stores/product'
import type { CartItem, CartItemStatus, Totals, Money } from '@/modules/nuxt-api/models/Cart'

export type CartShortItem = {
  id: number
  product: {
    id: number
  }
  quantity: number
  status: CartItemStatus
}

type State = {
  itemsList: CartItem[] | CartShortItem[]
  totals: Totals | undefined
  money: Money | undefined
  loadingId: number | null
  popoverCartShow: boolean
  isCartRequested: boolean
  isProductRequested: boolean
  helpProduct: Product.AsObject | undefined
}

class CartClickError extends Error {
  productId: number | undefined

  constructor (message: string, productId?: number) {
    super(message)

    this.productId = productId
  }
}

// TODO request sending on multiple click
export function useCartStore () {
  const nuxtApp = useNuxtApp()
  const api = useAPI()
  const sentry = useSentry()
  const state = useHydrationStore<State>('cart-store', {
    itemsList: [],
    money: undefined,
    totals: {},
    loadingId: null,
    popoverCartShow: false,
    isCartRequested: false,
    isProductRequested: false,
    helpProduct: undefined
  }, { disableHydration: true })

  const cartIdToSet = ref()
  const amountToSet = ref()

  const setItemsQuantity = useDebounceFn(() => {
    const { setItemQuantityInMyCart, removeItemsFromMyCart } = api.cart()

    if (cartIdToSet.value && amountToSet.value === 0) {
      return removeItemsFromMyCart([cartIdToSet.value])
    }

    return setItemQuantityInMyCart(cartIdToSet.value, amountToSet.value)
  }, 300)

  function sendCartEvent (_eventName: string, _info = {}) {
    // if (nuxtApp.$sentry) {
    //   const { singleEventLogger } = nuxtApp.$sentry
    //   singleEventLogger(eventName, 'message', undefined, info)
    // }
  }

  function sendUnexpectedError (message: string, id?: number) {
    const err = new CartClickError(message, id)
    sentry.captureException(err)
  }

  function popoverShow (productId: number) {
    const isMobile = window?.innerWidth < MIN_LAPTOP_WIDTH
    if (!isMobile && state.value.loadingId === productId) {
      state.value.popoverCartShow = true
      const timeout = setTimeout(() => {
        state.value.popoverCartShow = false
        clearTimeout(timeout)
      }, 1500)
    }
  }

  async function getCart () {
    if (state.value.isCartRequested) { return }

    try {
      const { getMyCart } = api.cart()
      const [itemsList, totals] = await nuxtApp.runWithContext(() => useSharedPromise(`cart`, getMyCart))
      state.value.isCartRequested = true
      state.value.itemsList = itemsList
      state.value.totals = totals
      state.value.money = totals?.total
    } catch {}
  }

  async function getCartShort () {
    try {
      state.value.isCartRequested = false
      const { getMyCartShort } = api.cart()
      const [products, money] = await getMyCartShort()
      console.log(products)
      console.log(money)

      state.value.itemsList = products.map(([id, quantity]) => ({
        id,
        product: { id },
        quantity,
        status: 0
      }))

      state.value.money = money
    } catch {}
  }

  async function initCart (isCartPage?: boolean) {
    await (isCartPage ? getCart : getCartShort)()
    console.log(state.value.itemsList)
  }

  async function getNapkins () {
    if (state.value.isProductRequested) { return }
    try {
      state.value.isProductRequested = true
      // const { getProduct } = useProductStore()
      // const { product } = await getProduct('napkins', false)
      // TODO: раскоментировать после мск
      // state.value.helpProduct = product
    } catch {}
  }

  async function addProductToCart (id?: number, quantity = 1, timeout: NodeJS.Timeout | undefined = undefined) {
    const clickTimeout = timeout ?? setTimeout(() => {
      sendUnexpectedError('add product to cart error', id)
      clearTimeout(clickTimeout)
    }, 10000)

    if (id === undefined) {
      clearTimeout(clickTimeout)
      return
    }

    state.value.loadingId = id
    const { addProductToMyCart } = api.cart()

    try {
      state.value.isCartRequested = true

      const [itemsList, totals] = await addProductToMyCart(id, quantity)
      clearTimeout(clickTimeout)

      if (!itemsList.length && !totals) {
        state.value.loadingId = null
        return
      }

      nuxtApp.runWithContext(() => {
        if (nuxtApp.$google) {
          nuxtApp.$google.event('add_to_cart', {
            id,
            quantity
          })
        }
      })

      state.value.itemsList = itemsList
      state.value.totals = totals
      state.value.money = totals?.total
      state.value.popoverCartShow = false

      popoverShow(id)
    } catch (_err) {
      clearTimeout(clickTimeout)
    } finally {
      state.value.loadingId = null
    }
  }

  async function removeProductFromCart (ids: number[]) {
    try {
      const { removeItemsFromMyCart } = api.cart()
      const [itemsList, totals] = await removeItemsFromMyCart(ids)

      state.value.isCartRequested = true
      state.value.itemsList = itemsList
      state.value.totals = totals
      state.value.money = totals?.total
    } catch {}
  }

  async function removeSelectedProducts () {
    if (state.value.itemsList.length === cartProductsSelected.value.length) {
      await emptyCart()
      return
    }
    await removeProductFromCart(cartProductsSelected.value)
  }

  async function removeDisabledProducts () {
    if (state.value.itemsList.length === cartProductsDisabled.value.length) {
      await emptyCart()
      return
    }
    await removeProductFromCart(cartProductsDisabled.value)
  }

  async function setCartProductAmount ({ id, amount, timeout }: { id: number | undefined, amount: number, timeout?: NodeJS.Timeout }) {
    if (id === undefined) { return }

    const cartId = state.value.itemsList.find(el => el.product?.id === id)?.id

    if (cartId === undefined) {
      await addProductToCart(id, amount, timeout)
      return
    }

    state.value.loadingId = id
    cartIdToSet.value = cartId
    amountToSet.value = amount

    setItemsQuantity()
      .then((value) => {
        const [itemsList, totals] = value
        if ((!itemsList.length && !totals) || amountToSet.value !== amount) { return }

        state.value.isCartRequested = true
        state.value.itemsList = itemsList
        state.value.totals = totals
        state.value.money = totals?.total
      })
      .catch(() => undefined)
      .finally(() => {
        state.value.loadingId = null
      })
  }

  async function emptyCart () {
    try {
      const { emptyMyCart } = api.cart()
      const [itemsList, totals] = await emptyMyCart()

      state.value.isCartRequested = true
      state.value.itemsList = itemsList
      state.value.totals = totals
      state.value.money = totals?.total
    } catch {}
  }

  async function selectCartProduct (ids: number[]) {
    try {
      const { selectItemsInMyCart } = api.cart()
      const [itemsList, totals] = await selectItemsInMyCart(ids)
      state.value.itemsList = itemsList
      state.value.totals = totals
      state.value.money = totals?.total
    } catch {}
  }

  async function selectAllProducts () {
    await selectCartProduct(cartProductsDeselected.value)
  }

  async function deselectCartProduct (ids: number[]) {
    try {
      const { deselectItemsInMyCart } = api.cart()
      const [itemsList, totals] = await deselectItemsInMyCart(ids)
      state.value.itemsList = itemsList
      state.value.totals = totals
      state.value.money = totals?.total
    } catch {}
  }

  async function deselectAllProducts () {
    await deselectCartProduct(cartProductsSelected.value)
  }

  const totalProductsAmount = computed(() => {
    if (process.server) { return 0 }

    return state.value.itemsList.reduce((acc, item) => {
      acc += item.quantity
      return acc
    }, 0)
  })

  const cartProductsDeselected = computed(() => state.value.itemsList
    .filter(el => (el as CartItem).status === 1)
    .map(el => el.id))

  const cartProductsSelected = computed(() => state.value.itemsList
    .filter(el => (el as CartItem).status === 2)
    .map(el => el.id))

  const cartProductsDisabled = computed(() => state.value.itemsList
    .filter(el => (el as CartItem).status === 3)
    .map(el => el.id))

  return {
    totalProductsAmount,
    cartProductsDeselected,
    cartProductsSelected,
    cartProductsDisabled,
    sendCartEvent,
    initCart,
    addProductToCart,
    removeProductFromCart,
    removeSelectedProducts,
    removeDisabledProducts,
    setCartProductAmount,
    emptyCart,
    selectCartProduct,
    selectAllProducts,
    deselectCartProduct,
    popoverShow,
    deselectAllProducts,
    sendUnexpectedError,
    getNapkins,

    popoverCartShow: toRef(state.value, 'popoverCartShow'),
    isCartRequested: toRef(state.value, 'isCartRequested'),
    itemsList: toRef(state.value, 'itemsList'),
    money: toRef(state.value, 'money'),
    totals: toRef(state.value, 'totals'),
    loadingId: toRef(state.value, 'loadingId'),
    helpProduct: toRef(state.value, 'helpProduct')
  }
}
