import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import { loadScript } from '../utils'
import { useConfig } from '@/stores/config'
import type { RegionsEnvsConfig } from '@/types/regionEnvs'

export class Flocktory {
  id = 0

  constructor (id: number) {
    this.id = id
  }

  createProductDiv (data: { id?: string, category?: string, isAvailable?: string, vendor?: string } = {}) {
    const div = document.createElement('div')

    if (data.id) div.setAttribute('data-fl-item-id', data.id)
    if (data.category) div.setAttribute('data-fl-item-category-id', data.category)
    if (data.isAvailable) div.setAttribute('data-fl-item-available', data.isAvailable)
    if (data.vendor) div.setAttribute('data-fl-item-vendor', data.vendor)

    return div
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { currentRegion } = useConfig()
  const analyticsRegionsJson = config.public.regionsEnvsJson as unknown as RegionsEnvsConfig
  const id = analyticsRegionsJson[currentRegion.value.id]?.flocktoryId as number

  if (isNaN(id)) {
    return
  }

  const flocktory = new Flocktory(id)

  let loaded = false

  nuxtApp.hook('page:finish', () => {
    if (loaded) { return }
    loadScript(id)
      .src(`https://api.flocktory.com/v2/loader.js?site_id=${id}`)
      .config({
        async: true,
        crossOrigin: 'anonymous'
      })
      .load()
    loaded = true
  })

  nuxtApp.hooks.callHook('analytics:flocktory:init')

  return {
    provide: {
      flocktory
    }
  }
})
