import type { Message } from 'google-protobuf'
import type { Request, UnaryInterceptor, UnaryResponse } from 'grpc-web'

type InterceptorRequest = Request<Message, Message>
type InterceptorUnaryResponse = UnaryResponse<Message, Message>

export class TokenAuthInterceptor implements UnaryInterceptor<Message, Message> {
  csrfToken: string
  sessionToken: string

  constructor (csrfToken: string, sessionToken: string) {
    this.csrfToken = csrfToken
    this.sessionToken = sessionToken
  }

  intercept (
    request: InterceptorRequest,
    invoker: (invokerRequest: InterceptorRequest) => Promise<InterceptorUnaryResponse>
  ): Promise<InterceptorUnaryResponse> {
    if (this.csrfToken) {
      request.getMetadata()['X-csrf-token'] = this.csrfToken
    }

    if (import.meta.server && this.sessionToken) {
      request.getMetadata()['cookie'] = 'SHOP_SESSION_TOKEN=' + this.sessionToken
    }

    return invoker(request)
  }
}
