import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import { useContextData, useHydrationRef, useNuxtApp, useSentry } from '#imports'
import { MetadataInterceptor } from './interceptors/metadata-interceptor'
import { ErrorInterceptor } from './interceptors/error-interceptor'
import { useConfig } from '@/stores/config'
import { NetworkInterceptor } from '@/modules/nuxt-api/interceptors/network-interceptor'

export default defineNuxtPlugin({
  name: 'nuxt-api',
  async setup () {
    const nuxtApp = useNuxtApp()
    const { currentRegion } = useConfig()
    const runtimeConfig = useRuntimeConfig().public
    const apiInterceptors = useContextData<any>('api-interceptors', [])
    const currentLocale = useHydrationRef('current-locale', '')
    const sentry = useSentry()

    const locale = currentLocale.value === 'ru' ? 'rus' : currentLocale.value === 'en' ? 'eng' : currentLocale.value
    const region = currentRegion.value?.id.toString()

    apiInterceptors.value.push(new MetadataInterceptor(sentry, locale, region, runtimeConfig.apiGrpcTimeoutInS))
    // @ts-ignore
    apiInterceptors.value.push(new ErrorInterceptor(sentry, nuxtApp))

    if (import.meta.client) {
      // @ts-ignore
      apiInterceptors.value.push(new NetworkInterceptor(nuxtApp))
    }
  }
})
